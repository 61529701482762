import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useApi from "../../useApi";
import moment from "moment";
import { Button, ButtonGroup, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SbLineChart from "../common/charts/sbLineChart";
import chartHelpers from "../utils/chart-helpers";
import { HistoricalApiResponse } from "./model/historical-price-response.model";
import { Box } from "@mui/material";
import { a11yProps, CustomTabPanel } from "../common/tabs/SbTabs";

const StockHistoryChart = (props: any) => {
  const { postRequest } = useApi();
  let defaultChartData: any[] = [];
  // eslint-disable-next-line
  const [fourtyYChartData, setFourtyYChartData] = useState(defaultChartData); // eslint-disable-next-line
  const [oneDayChartData, setOneDayChartData] = useState(defaultChartData);
  // eslint-disable-next-line
  const [isChartDataLoaded, setIsChartDataLoaded] = useState(false);
  const [isPriceChartByPercentage, setIsPriceChartByPercentage] =
    useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const dateFormat = "YYYY-MM-DD";
  let currentDate = new Date();
  const {
    historicalChartOptions,
    getHistoricalDataChart,
    getOneDayHistoricalDataChart,
  } = chartHelpers();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth < 960);
    });
    initHistoryByYear(40);
    getOneDayHistoricalData();
    // eslint-disable-next-line
  }, []);
  const handlePostApiCall = async <T,>(
    path: string,
    postBody: any,
    callback: (data: T) => void
  ) => {
    try {
      const response = await postRequest(path, postBody);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {}
  };
  const getOneDayHistoricalData = () => {
    const postData = {
      symbol: props?.symbol,
      fromDate: "",
      toDate: "",
    };
    handlePostApiCall<any>(
      "/api/stockProfileDetails/one-day-historical",
      postData,
      (data) => {
        setOneDayChartData(data);
      }
    );
  };
  const onPriceViewClick = () => {
    setIsPriceChartByPercentage(false);
    props?.chartTypeChange(false);
  };
  const onPercentageViewClick = () => {
    setIsPriceChartByPercentage(true);
    props?.chartTypeChange(true);
  };
  const initHistoryByYear = (yearFrequency: number) => {
    const fromDate = moment(
      new Date().setFullYear(currentDate.getFullYear() - yearFrequency)
    ).format(dateFormat);
    const formattedToDate = moment(currentDate).format(dateFormat);
    const postData = {
      symbol: props?.symbol,
      fromDate: fromDate,
      toDate: formattedToDate,
    };
    handlePostApiCall<HistoricalApiResponse>(
      "/api/stockProfileDetails/historical",
      postData,
      (data) => {
        setFourtyYChartData(data?.historical);
        setIsChartDataLoaded(true);
      }
    );
  };

  function onToolTipChange(data: any) {
    props.onToolTipChange(data);
  }
  return isChartDataLoaded ? (
    <Grid container>
      <Grid item xs={isMobileView ? 12 : 9}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="1D" {...a11yProps(0)} />
          <Tab label="5D" {...a11yProps(1)} />
          <Tab label="1M" {...a11yProps(2)} />
          <Tab label="6M" {...a11yProps(3)} />
          <Tab label="1Y" {...a11yProps(4)} />
          <Tab label="5Y" {...a11yProps(5)} />
          <Tab label="10Y" {...a11yProps(6)} />
          <Tab label="20Y" {...a11yProps(7)} />
          <Tab label="40Y" {...a11yProps(8)} />
        </Tabs>
      </Grid>
      <Grid item xs={isMobileView ? 12 : 3}>
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="Basic button group"
          className={isMobileView ? "chart-btn-group" : ""}
        >
          <Button
            variant={isPriceChartByPercentage ? "outlined" : "contained"}
            className="no-txt-transform"
            onClick={onPriceViewClick}
          >
            Price View
          </Button>
          <Button
            variant={isPriceChartByPercentage ? "contained" : "outlined"}
            className="no-txt-transform"
            onClick={onPercentageViewClick}
          >
            Percentage View
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <CustomTabPanel value={value} index={0}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 0 && oneDayChartData?.length > 0 && (
                <SbLineChart
                  data={getOneDayHistoricalDataChart(oneDayChartData)}
                  options={historicalChartOptions(props?.currency, undefined)}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={false}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 1 && fourtyYChartData.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "5D",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 2 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "1M",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={3}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 3 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "6M",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={4}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 4 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "1Y",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={5}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 5 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "5Y",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={6}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 6 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "10Y",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={7}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 7 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "20Y",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 8 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={getHistoricalDataChart(
                    fourtyYChartData,
                    "40Y",
                    isPriceChartByPercentage
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={!isPriceChartByPercentage}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
      </Grid>
    </Grid>
  ) : (
    <div></div>
  );
};

export default StockHistoryChart;
