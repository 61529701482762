import { Grid, Typography } from "@mui/material";
import companyprofile from "../../images/SCHD.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CompanyProfile } from "../../models/StockProfile";
import StockHistoryChart from "./stock-history-chart";
import useState from "react-usestateref";
import appUtils from "../../shared/utils";
import EtfStockProfileAccordians from "./etf-stock-profile-accordians";
import AddRemoveFavourite from "../common/addRemoveFavourite";
import EtfPeers from "./etf-peers";
import { ChartToolTip } from "./model/chart-tooltip-change.model";
import { TimeMachine } from "./timeMachine";
import Tooltip from "@mui/material/Tooltip";

const EtfProfile = (props: any) => {
  const { formatToTwoDecimal } = appUtils();

  const [chartToolTip, setChartToolTip] = useState<ChartToolTip>({
    label: new Date().toLocaleString(),
    formattedValue: "0",
  });

  const stockProfileInfo: CompanyProfile = props?.stock as CompanyProfile;
  const [isFavourite, setIsFavourite] = useState<boolean>(
    Boolean(stockProfileInfo?.isFavorite)
  );
  const [isPriceChartByPercentage, setIsPriceChartByPercentage] =
    useState(false);
  // eslint-disable-next-line
  const addStockToFavourite = () => {
    setIsFavourite(true);
  };

  const removeStockFromFavourite = () => {
    setIsFavourite(false);
  };
  const chartTypeChange = (isPriceChart: boolean) => {
    setIsPriceChartByPercentage(isPriceChart);
  };

  function onToolTipChange(data: ChartToolTip) {
    if (
      chartToolTip?.label !== data?.label ||
      chartToolTip?.formattedValue !== data?.formattedValue
    ) {
      setChartToolTip(data);
    }
  }

  return (
    <>
      <div className="p-3">
        <Grid container>
          <Grid item={true} xs={12} md={6} lg={8}>
            <div className="d-flex align-items-center justify-content-center flex-column flex-md-row justify-content-md-start mb-3">
              <div className="flex-shrink-0">
                <img
                  src={companyprofile}
                  alt="Company Profile"
                  className="object-fit-contain"
                  width="120"
                  height="120"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="d-flex align-items-center gap-2 my-2 justify-content-start">
                  <Tooltip
                    title={stockProfileInfo?.companyName}
                    placement="bottom"
                    arrow
                  >
                    <div className="primary-header header-txt-truncate">
                      {stockProfileInfo?.companyName}. Dividend Equity ETF
                    </div>
                  </Tooltip>
                  <AddRemoveFavourite
                    stock={stockProfileInfo}
                    backgroundColor={"#ffff"}
                    isFavorite={isFavourite}
                    addToFavouriteStock={addStockToFavourite}
                    unFavouriteStock={removeStockFromFavourite}
                  ></AddRemoveFavourite>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={6} lg={4}>
            <div className="d-flex flex-column justify-content-center justify-content-md-end justify-content-lg-end align-items-center align-items-md-end align-items-ld-end">
              <div className="primary-header mb-1">
                {formatToTwoDecimal(stockProfileInfo?.Price)}{" "}
                {stockProfileInfo?.currency}
              </div>
              <div className="stock-value-header mb-1">
                <span className="positive">{stockProfileInfo?.Changes}</span>(
                {formatToTwoDecimal(stockProfileInfo?.ChangesPercentage)}%){" "}
              </div>
              <div className="secondary-header mb-1">
                <span>{stockProfileInfo?.exchangeShortName}</span> :{" "}
                <span>{stockProfileInfo?.Symbol}</span>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="mb-3">
          <StockHistoryChart
            symbol={"'" + stockProfileInfo?.Symbol + "'"}
            currency={stockProfileInfo?.currency}
            onToolTipChange={onToolTipChange}
            chartTypeChange={chartTypeChange}
          ></StockHistoryChart>
        </div>

        {!isPriceChartByPercentage && (
          <div className="d-flex justify-content-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex-grow-1 ms-3">
                <TimeMachine
                  currentStockPrice={stockProfileInfo?.Price}
                  chartToolTip={chartToolTip}
                  currency={stockProfileInfo?.currency}
                />
              </div>
            </div>
          </div>
        )}

        <div
          className={isPriceChartByPercentage ? "chart-percentage-view" : ""}
        >
          <Typography variant="h5" className="mb-2">
            About
          </Typography>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium pe-2">
                Ipo Date: {stockProfileInfo?.ipoDate}
              </span>
            </li>
          </ul>
          <div className="primary-txt-16 mb-3">
            {stockProfileInfo?.description}
          </div>
        </div>

        <EtfStockProfileAccordians stock={stockProfileInfo} />
        <EtfPeers symbol={"'" + stockProfileInfo?.Symbol + "'"}></EtfPeers>
      </div>
    </>
  );
};

export default EtfProfile;
