import { ChartType, Tooltip, TooltipPositionerFunction } from "chart.js";

import moment from "moment";
import { HistoricalEarnings } from "../../models/StockProfile";
declare module "chart.js" {
  interface TooltipPositionerMap {
    myCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}
Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
  const chart = this.chart;
  return {
    x: eventPosition.x,
    y: chart.chartArea.top,
    xAlign: "center",
    yAlign: "bottom",
  };
};
const chartHelpers = () => {
  const getOneDayHistoricalDataChart = (result: any[]) => {
    if (!result || result?.length === 0) {
      return defaultEmptyChartResponse();
    }
    const lastDate = result[0]?.date.substring(0, 10);
    const dateLabel = Object.values(
      result
        .filter(
          (x: { date: string | number | Date }) =>
            new Date(x.date) >= new Date(lastDate)
        )
        .map((x: { date: string }) => moment(new Date(x.date)).format("h:mm a"))
    ).reverse();
    const stockCloseValue = Object.values(
      result
        //.filter((x: { date: number; })=> x.date > 2022-12-15 )
        .filter(
          (x: { date: string | number | Date }) =>
            new Date(x.date) >= new Date(lastDate)
        )
        .map((x: { close: any }) => Math.round(x.close * 100) / 100)
    ).reverse();

    let historicalChart = {} as any;
    historicalChart.labels = dateLabel;
    historicalChart.datasets = [];
    historicalChart.datasets.push({
      label: "stock value",
      data: stockCloseValue,
      borderColor: "#715398",
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 8,
      showLine: true,
      spanGaps: true,
    });
    return historicalChart;
  };
  const getHistoricalDataChart = (
    result: any[],
    type: string = "",
    isPercentageView?: boolean
  ) => {
    if (!result || result?.length === 0) {
      return defaultEmptyChartResponse();
    }
    const dateValue = new Date();
    switch (type) {
      case "5D":
        result = result?.slice(0, 5);
        break;
      case "1M":
        dateValue.setMonth(dateValue.getMonth() - 1);
        result = result?.filter(
          (x: { date: string | number | Date }) => new Date(x.date) >= dateValue
        );

        break;
      case "6M":
        dateValue.setMonth(dateValue.getMonth() - 6);
        result = result?.filter(
          (x: { date: string | number | Date }) => new Date(x.date) >= dateValue
        );
        break;
      case "1Y":
        dateValue.setFullYear(dateValue.getFullYear() - 1);
        result = result?.filter(
          (x: { date: string | number | Date }) => new Date(x.date) >= dateValue
        );
        break;
      case "5Y":
        dateValue.setFullYear(dateValue.getFullYear() - 5);
        result = result?.filter(
          (x: { date: string | number | Date }) => new Date(x.date) >= dateValue
        );
        break;
      case "10Y":
        dateValue.setFullYear(dateValue.getFullYear() - 10);
        result = result?.filter(
          (x: { date: string | number | Date }) => new Date(x.date) >= dateValue
        );
        break;
      case "20Y":
        dateValue.setFullYear(dateValue.getFullYear() - 20);
        result = result?.filter(
          (x: { date: string | number | Date }) => new Date(x.date) >= dateValue
        );
        break;
      default:
        break;
    }
    const date = Object.values(
      result.map((x: { date: string }) => moment(new Date(x.date)).format("ll"))
    ).reverse();

    const stockCloseValue = Object.values(
      result.map((x: { close: any }) => Math.round(x.close * 100) / 100)
    ).reverse();
    let historicalChart = {} as any;
    historicalChart.labels = date;
    historicalChart.datasets = [];
    if (!isPercentageView) {
      historicalChart.datasets.push({
        label: "stock value",
        data: stockCloseValue,
        borderColor: "#715398",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 8,
        showLine: true,
        spanGaps: true,
        yAxisID: "y",
      });
    }

    const hasChangePercentage = result.some((x) => x?.changePercent);
    if (isPercentageView && hasChangePercentage) {
      const stockChangePercentages = Object.values(
        result.map((x: { changePercent: any }) => x?.changePercent || 0)
      ).reverse();
      const cummulativePercentage = aggregatePercentage(stockChangePercentages);
      historicalChart.datasets.push({
        label: "stock percentage",
        data: cummulativePercentage,
        borderColor: "#e67e22",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 8,
        showLine: true,
        spanGaps: true,
        yAxisID: "y1",
      });
    }
    return historicalChart;
  };
  const assetsVsLiabilitiesOptions = () => {
    return {
      maintainAspectRatio: true,
      plugins: {
        title: {
          display: false,
          text: "Short-term Assets vs Liabilities",
          fontSize: 20,
        },
        legend: {
          position: "bottom",
          display: true,
          labels: {
            textAlign: "left",
          },
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
            text: "Year",
          },
        },
      },
    };
  };
  const epsEarningsOptions = () => {
    return {
      maintainAspectRatio: true,
      plugins: {
        title: {
          display: false, //
          text: "Earnings Trend",
          fontSize: 20,
        },
        legend: {
          position: "bottom",
          display: true, //
          labels: {
            textAlign: "left",
          },
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
            text: "Quarter",
          },
        },
      },
    };
  };
  const etfSectorOptions = () => {
    return {
      plugins: {
        legend: {
          maxHeight: 800,
          display: true,
          position: "right",
          labels: {
            color: "#000",
          },
        },
      },
    };
  };
  const incomeStatementRevenueOptions = () => {
    return {
      plugins: {
        title: {
          display: true,
          text: "Revenue Growth",
          fontSize: 20,
        },
        legend: {
          position: "bottom",
          display: false,
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
            text: "Year",
          },
        },
        y: {
          ticks: {
            callback: (labelValue: any) => {
              return "$" + labelValue;
            },
          },
        },
      },
    };
  };
  const incomeStatementNetIncomeOptions = () => {
    return {
      plugins: {
        title: {
          display: true,
          text: "Profit Growth",
          fontSize: 20,
        },
        legend: {
          position: "bottom",
          display: false,
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
            text: "Year",
          },
        },
        y: {
          ticks: {
            callback: (labelValue: any) => {
              return "$" + labelValue;
              //   return (
              //   "$" +
              //     this.convertToInternationalCurrencySystem(labelValue)
              //   );
            },
          },
        },
      },
    };
  };
  const etfDividendOptions = () => {
    return {
      plugins: {
        legend: {
          labels: {
            color: "#000",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#000",
          },
          grid: {
            color: "#eee",
          },
        },
        y: {
          ticks: {
            color: "#000",
          },
          grid: {
            color: "#ddd",
          },
        },
      },
    };
  };
  const historicalChartOptions = (
    currencySymbol: string,
    toolTipChangeCallBack: any
  ) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      scales: {
        y: {
          display: false,
          ticks: {
            display: true,
            maxTicksLimit: 5,
            font: {
              size: 10,
              weight: 600,
            },
            // Include a dollar sign in the ticks
            callback: (value: number) => {
              return currencySymbol + value.toFixed(2);
            },
          },
          grid: {
            display: false,
          },
        },
        y1: {
          display: false,
          ticks: {
            display: true,
            maxTicksLimit: 5,
            font: {
              size: 10,
              weight: 600,
            },
          },
          grid: {
            display: false,
          },
        },
        x: {
          title: {
            display: false,
            text: "Date",
          },
          grid: {
            tickColor: "black",
            display: false,
            font: {
              size: 10,
              weight: 600,
            },
          },
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0,
            color: "black",
            font: {
              size: 10,
              weight: 300,
            },
          },
        },
      },
      plugins: {
        title: {
          display: false,
          text: "Stock History",
          fontSize: 20,
        },
        legend: {
          position: "bottom",
          display: false,
          labels: {
            textAlign: "left",
          },
        },
        tooltip: {
          position: "myCustomPositioner",
          callbacks: {
            title: (t: any, d: any) => {
              const formattedValue = t[0]?.formattedValue;
              const label = t[0].label;
              return currencySymbol + "  " + formattedValue + " | " + label;
            },
            label: function (context: any) {
              const valueAxis = "y";
              let label = context.dataset.label || "";
              const dateLabel = context?.label;
              const formattedValue = context?.raw;
              if (toolTipChangeCallBack) {
                setTimeout(() => {
                  toolTipChangeCallBack({
                    formattedValue: formattedValue || 1,
                    label: dateLabel,
                  });
                }, 200);
              }
              if (label && context?.dataset?.yAxisID === valueAxis) {
                label += " " + currencySymbol + " : ";
              } else {
              }

              if (context.parsed.y !== null) {
                label +=
                  context?.dataset?.yAxisID === valueAxis
                    ? context.parsed.y
                    : " : " + context.parsed.y + " % ";
              }
              return label;
            },
          },
        },
      },
    };
  };
  const createAssetsVsLiabilitiesChart = (result: any[]) => {
    if (result?.length === 0) {
      return defaultEmptyChartResponse();
    }
    const year = Object.values(
      result?.map((x: { date: string }) => x.date.substring(0, 4))
    ).reverse();

    const currentRatio = Object.values(
      result?.map(
        (x: { currentRatio: any }) => Math.round(x.currentRatio * 100) / 100
      )
    ).reverse();

    const assetsVsLiabilitiesData = {
      labels: year,
      datasets: [
        {
          label: "Current Ratio",
          data: currentRatio,
          fill: true,
          borderColor: "#FFA726",
          tension: 0.4,
          backgroundColor: "rgba(255,167,38,0.2)",
        },
      ],
    };
    assetsVsLiabilitiesData.datasets = [];
    assetsVsLiabilitiesData.datasets.push({
      label: "Current Ratio",
      data: currentRatio,
      fill: true,
      borderColor: "#FFA726",
      tension: 0.4,
      backgroundColor: "rgba(255,167,38,0.2)",
    });

    assetsVsLiabilitiesData.datasets.push({
      label: "Break-even Point",
      data: [1, 1, 1, 1, 1],
      fill: false,
      borderColor: "#000",
      tension: 0.4,
      backgroundColor: "",
    });
    return assetsVsLiabilitiesData;
  };
  const createIncomeStatementChart = (result: any[]) => {
    if (result?.length === 0) {
      return {
        incomeData: defaultEmptyChartResponse(),
        revenueData: defaultEmptyChartResponse(),
      };
    }
    const incomeStatementYear = Object.values(
      result?.map((x: { calendarYear: string }) => x.calendarYear)
    ).reverse();

    const incomeStatementRevenue = Object.values(
      result?.map((x: { revenue: number }) => x.revenue)
    ).reverse();

    const incomeStatementNetIncome = Object.values(
      result?.map((x: { netIncome: number }) => x.netIncome)
    ).reverse();

    const incomeStatementRevenueData = {
      labels: incomeStatementYear,
      datasets: [
        {
          label: "Revenue Growth",
          data: incomeStatementRevenue,
          fill: true,
          borderColor: "#FFA726",
          tension: 0.4,
          backgroundColor: "rgba(255,167,38,0.2)",
        },
      ],
    };
    incomeStatementRevenueData.datasets = [];
    incomeStatementRevenueData.datasets.push({
      label: "Revenue Growth",
      data: incomeStatementRevenue,
      fill: true,
      borderColor: "#FFA726",
      tension: 0.4,
      backgroundColor: "rgba(255,167,38,0.2)",
    });

    const incomeStatementNetIncomeData = {
      labels: incomeStatementYear,
      datasets: [
        {
          label: "Profit Growth",
          data: incomeStatementNetIncome,
          fill: true,
          tension: 0.4,
        },
      ],
    };
    incomeStatementNetIncomeData.datasets = [];
    incomeStatementNetIncomeData.datasets.push({
      label: "Profit Growth",
      data: incomeStatementNetIncome,
      fill: true,
      tension: 0.4,
    });
    return {
      incomeData: incomeStatementNetIncomeData,
      revenueData: incomeStatementRevenueData,
    };
  };
  const createEtfDividendChart = (result: any[]) => {
    if (!result || result?.length === 0) {
      return defaultEmptyChartResponse();
    }
    const labels = Object.values(
      result?.map((x: { label: string }) => x.label)
    ).reverse();

    const data = Object.values(
      result?.map((x: { dividend: number }) => x.dividend)
    ).reverse();

    const etfDividendData = {
      labels: labels, // ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: "Quarterly Dividend Payout",
          backgroundColor: "#42A5F5",
          data: data, //[65, 59, 80, 81, 56, 55, 40]
        },
      ],
    };
    return etfDividendData;
  };
  const createEtfSectorWeightingsChart = (result: any[]) => {
    if (result?.length === 0) {
      return defaultEmptyChartResponse();
    }

    const backgroundColor = [
      "#0074D9",
      "#FF4136",
      "#2ECC40",
      "#FF851B",
      "#7FDBFF",
      "#B10DC9",
      "#FFDC00",
      "#001f3f",
      "#39CCCC",
      "#01FF70",
      "#85144b",
      "#F012BE",
      "#3D9970",
      "#111111",
      "#AAAAAA",
    ];
    const labels = Object.values(
      result?.map((x) => `${x.sector} - ${x.weightPercentage}`)
    );

    const data = Object.values(
      result?.map((x: { weightPercentage: string }) =>
        parseFloat(x.weightPercentage.slice(0, -1))
      )
    );
    const etfSectorData = {
      labels: labels, //['A','B','C'],
      datasets: [
        {
          data: data, //[300, 50, 100],
          backgroundColor: backgroundColor,
          // hoverBackgroundColor: [
          //     "#64B5F6",
          //     "#81C784",
          //     "#FFB74D"
          // ]
        },
      ],
    };
    return etfSectorData;
  };
  const compareEpsVsEstimatedChart = (result: HistoricalEarnings[]) => {
    if (result?.length === 0) {
      return defaultEmptyChartResponse();
    }
    const date = Object.values(
      result?.map((x: { date: string }) =>
        moment(new Date(x.date)).format("MMM, y")
      )
    ).reverse();

    const eps = Object.values(
      result?.map(
        (x: { actualEarningResult: number | null }) => x.actualEarningResult
      )
    ).reverse();

    const epsEstimated = Object.values(
      result?.map((x: { estimatedEarning: number }) => x.estimatedEarning)
    ).reverse();

    const epsEarningsData = {
      labels: date,
      datasets: [
        {
          label: "Estimated EPS",
          data: [] as any,
          fill: false,
          borderColor: "#FFA726",
          tension: 0.4,
          //backgroundColor: 'rgba(255,167,38,0.2)',
        },
      ],
    };
    epsEarningsData.labels = date;
    epsEarningsData.datasets = [];

    epsEarningsData.datasets.push({
      label: "Estimated EPS",
      data: epsEstimated,
      fill: false,
      borderColor: "#FFA726",
      tension: 0.4,
      //backgroundColor: 'rgba(255,167,38,0.2)',
    });

    // below is a fix for getting eps as 0 instead of null in some cases.
    if (eps && eps.length && eps[eps.length - 1] === 0) {
      eps[eps.length - 1] = null;
    }

    epsEarningsData.datasets.push({
      label: "Actual EPS",
      data: eps,
      fill: false,
      tension: 0.4,
      borderColor: "rgb(75, 192, 192)",
    });
    return epsEarningsData;
  };
  const defaultEmptyChartResponse = () => {
    return { labels: {}, datasets: [] };
  };
  const aggregatePercentage = (values: any[]) => {
    let cumulativeSum: any = [];
    for (let i = 0, s = values[0]; i < values.length; i++, s += values[i]) {
      cumulativeSum.push(s?.toFixed(2));
    }
    return cumulativeSum;
  };
  return {
    assetsVsLiabilitiesOptions,
    epsEarningsOptions,
    historicalChartOptions,
    createIncomeStatementChart,
    etfDividendOptions,
    etfSectorOptions,
    incomeStatementRevenueOptions,
    incomeStatementNetIncomeOptions,
    getOneDayHistoricalDataChart,
    getHistoricalDataChart,
    createAssetsVsLiabilitiesChart,
    createEtfDividendChart,
    createEtfSectorWeightingsChart,
    compareEpsVsEstimatedChart,
  };
};

export default chartHelpers;
